import { Container, Typography, Box } from '@material-ui/core';
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { PAIDY_HOST } from 'src/constants';
import Img12PayCampaign from 'src/images/merchant/campaign/general_cashback_202305/12pay_campaign.png';
import ImgPaidyCard from 'src/images/merchant/campaign/general_cashback_202305/paidycard.png';
import styles from './PaidyBenefits202305.module.scss';

const DATA = [
  {
    src: Img12PayCampaign,
    name: '12pay_campaign',
    note: '*口座振替・銀行振込のみ無料',
    href: `${PAIDY_HOST}/npay`,
  },
  { src: ImgPaidyCard, name: 'paidycard', href: `${PAIDY_HOST}/paidycard` },
];

export default function PaidyBenefits202305({ id, title }) {
  return (
    <section className={styles.section} id={id}>
      {title && <Typography className={styles.title}>{title}</Typography>}
      <Container className={styles.container}>
        {DATA.map((data) => (
          <Box>
            <OutboundLink
              href={data.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={data.src} alt={data.name} width={311} height={420} />
            </OutboundLink>
            {data.note && (
              <Typography className={styles.note}>{data.note}</Typography>
            )}
          </Box>
        ))}
      </Container>
    </section>
  );
}
