import React from 'react';
import BadgesByDevice from 'src/components/BadgesByDevice';
import Qr from 'src/images/qr-white-bg.png';
import PaidyIcon from 'src/images/paidy-pink.svg';
import styles from './JulyAppDownload.module.scss';

const APP_URLS_SP = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/GeneralCBCP',
  playStoreUrl: 'https://paidy.onelink.me/GTiS/GeneralCBCP',
};

const APP_URLS_PC = {
  appStoreUrl: 'https://paidy.onelink.me/GTiS/GeneralCBCP',
  playStoreUrl: 'https://paidy.onelink.me/KTFT/GeneralCBCP',
};

export default function JulyAppDownload() {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <img src={Qr} alt="qr" width={130} height={130} className={styles.qr} />
        <div className={styles.containerRight}>
          <div className={styles.containerPaidyLogo}>
            <img src={PaidyIcon} alt="paidy-icon" width={51} height={51} />
            <h2 className={styles.text}>
              ペイディアプリで
              <br />
              もっと便利に。
            </h2>
          </div>
          <BadgesByDevice
            appUrlsSp={APP_URLS_SP}
            appUrlsPc={APP_URLS_PC}
            className={styles.containerBadges}
          />
        </div>
      </div>
    </section>
  );
}
