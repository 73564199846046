import React from 'react';
import { Box, Container, Hidden } from '@material-ui/core';
import { PAIDY_HOST } from 'src/constants';
import LinearCTAButton from 'src/components/LinearCTAButton';
import Background from 'src/images/merchant/campaign/general_cashback_202311/background.png';
import Banner from 'src/images/merchant/campaign/general_cashback_202311/banner.png';
import Cloud0 from 'src/images/merchant/campaign/general_cashback_202311/cloud-0.png';
import Cloud1 from 'src/images/merchant/campaign/general_cashback_202311/cloud-1.png';
import Cloud2 from 'src/images/merchant/campaign/general_cashback_202311/cloud-2.png';
import styles from './CashbackTopBanner202311.module.scss';

export default function CashbackTopBanner202309() {
  return (
    <section className={styles.section}>
      <Container maxWidth="xl" className={styles.container}>
        <Box>
          <img alt="background" width={1440} height={626} src={Background} />
        </Box>
        <Box className={styles.content}>
          <Box className={styles.boxClouds}>
            <img
              alt="cloud-0"
              width={200}
              height={66}
              className={styles.cloud0}
              src={Cloud0}
            />
            <img
              alt="cloud-1"
              width={237}
              height={78}
              className={styles.cloud1}
              src={Cloud1}
            />
            <img
              alt="cloud-2"
              width={174}
              height={42}
              className={styles.cloud2}
              src={Cloud2}
            />
          </Box>
          <Box display="flex" justifyContent="center" mt={{ xs: 8, md: 2 }}>
            <Hidden xsDown>
              <img
                alt="banner"
                width={363}
                height={488}
                src={Banner}
                style={{ zIndex: 1 }}
              />
            </Hidden>

            <Hidden smUp>
              <img
                alt="banner"
                width={320}
                height={430}
                src={Banner}
                style={{ zIndex: 1 }}
              />
            </Hidden>
          </Box>
          <Box mt={{ xs: 3, sm: 1 }} display="flex" justifyContent="center">
            <LinearCTAButton
              text="使えるお店を探す"
              to={`${PAIDY_HOST}/shop`}
              isExternal
              className={styles.btn}
            />
          </Box>
        </Box>
      </Container>
    </section>
  );
}
