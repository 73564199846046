import React from 'react';
import Layout from 'src/components/Layout/Layout';
import JulyPayLater from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyPayLater';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyPaymentSteps';
import JulyAppDownload from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyAppDownload';
import { SITE_METADATA } from 'src/constants';
import { HeaderOnlyPaidyLogo, StickyNav } from 'src/components';
import { Box } from '@material-ui/core';
import Condition202311 from 'src/components/Merchant/Campaign/GeneralCashback202311/Condition202311';
import SectionNotes202311 from 'src/components/Merchant/Campaign/GeneralCashback202311/SectionNotes202311';
import CashbackBanner202311 from 'src/components/Merchant/Campaign/GeneralCashback202311/CashbackBanner202311';
import PaidyBenefits202305 from 'src/components/Merchant/Campaign/GeneralCashback202305/PaidyBenefits202305';
import CashbackTopBanner202311 from 'src/components/Merchant/Campaign/GeneralCashback202311/CashbackTopBanner202311';

const SEOProps = {
  title: SITE_METADATA.general_cashback_202311.title,
  description: SITE_METADATA.general_cashback_202311.description,
};
const NAVS = [
  'キャンペーン概要',
  'ペイディについて',
  'ペイディが選ばれる理由',
  'ご注意事項',
];

export default function GeneralCashback202311() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter backgroundColor="white">
      <HeaderOnlyPaidyLogo />
      <CashbackTopBanner202311 />
      <Box mt={4} />
      <StickyNav navs={NAVS} />
      <Condition202311 id={NAVS[0]} />
      <JulyPayLater
        id={NAVS[1]}
        description="AmazonやSHEINのほか、Visaマークのあるお店ならどこでも、スマホだけで今すぐお買い物ができるあと払いサービスです。お支払いは翌月でOK。さらに、リアルカードを発行すると、Visaマークのある街のお店でもペイディが利用可能に。"
      />
      <JulyPaymentSteps />
      <CashbackBanner202311 />
      <PaidyBenefits202305 id={NAVS[2]} title="ペイディが選ばれる理由" />
      <SectionNotes202311 id={NAVS[3]} />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
